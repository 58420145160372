
.divider-group {
    > ul {
        > li {
            &::before {
                background-color: var(--v-grey-lighten3);
            }
        }
    }
}
