
// btn-group
.btn-group {
    flex-wrap: wrap;
    margin: -4px;
    .v-btn {
        line-height: 1;
        opacity: 1;
        &.v-size--x-small.v-btn--icon {
            position: relative;
            width: 34px !important;
            height: 34px !important;
            padding: 0 !important;
        }
    }
    &--color {
        .v-btn {
            border-radius: 100% !important;
            overflow: hidden;
            border: 0 !important;
            &--active {
                &::before {
                    display: none;
                }
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                    border: 2px solid var(--v-grey-darken4) !important;
                }
            }
        }
    }
    &__colorchip {
        width: 30px;
        height: 30px;
        border-radius: 100%;
    }
}
