
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .product-cart__card {
        border-width: 0 !important;
        box-shadow: 0 0 10px var(--shadow) !important;
    }
}
@media (min-width: 1200px) {
}
